.tou-modal {
  width: 300px;
}

.tou-modal__title {
  color: #707070;
}

.tou-input {
  border: 1px solid #d9d9d9;
  outline: none;
}

.tou-input:focus {
  outline: none;
}
