.ant-switch {
  background-color: #f8a091 !important;
}

.ant-switch-checked {
  background-color: #32be32 !important;
}

.ant-table-cell {
  padding: 10px !important;
  text-align: center !important;
}

.color {
  color: #a3a3a3;
}

.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 18px !important;
  height: 18px !important;
  direction: ltr;
  background-color: #fff;
  border: 2px solid #7e7e7e !important;
  border-radius: 2px;
  border-collapse: separate;
  transition: all 0.3s;
}
