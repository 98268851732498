.mqtt-btn__c {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mqtt-cover.ton {
  width: 400px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

.mq-tou__e {
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  margin: 5px 0;
}

.mqtt-title {
  padding: 0 0 5px 0;
  text-align: center;
  margin: 5px 0 0 0;
}

.mqtt-btn.link {
  margin: 0 0 0 8px;
}

.mqtt-btn {
  width: 48%;
}

.mq-tou__v {
  font-size: 16px;
}
