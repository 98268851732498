.skimmer {
  border-radius: 2px;
  position: relative;
  overflow: hidden;
}

.skimmer-container {
  width: 900px;
  height: 400px;
  background: #ffffff1c;
  /* background: #ffffff37; */
  position: absolute;
  top: 0;
  left: -100px;
  /* transform: rotate(-20deg); */
  transform: rotate(-70deg);
  filter: blur(1);
  animation: skimmer;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes skimmer {
  from {
    left: -200px;
  }

  to {
    left: 1000px;
  }
}
