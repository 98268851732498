.mqtt-btn__c {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mqtt-cover.gen {
  width: 600px;
  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}

.mq-tou__e {
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  margin: 5px 0;
}

.mqtt-title {
  padding: 0 0 5px 0;
  text-align: center;
  margin: 5px 0 0 0;
}

.mqtt-btn.link {
  margin: 0 0 0 8px;
}

.mqtt-btn {
  width: 48%;
}

.gen-table {
  width: 100%;
  /* border: 1px solid green; */
  border-collapse: collapse;
}

.gen-th {
  font-weight: 510;
  padding: 5px;
  font-size: 12px;
  color: #5a75f8;
  text-transform: uppercase;
}

.gen-td {
  text-align: center;
}

.gen-tr {
  padding: 13px;
  border: 1px solid #eee;
}
