.meter-class__input {
  width: 100%;
  outline: none;
  background: #fbf7ff;
  border: 1px solid #6418c2;
  font-size: 13px;
}

.meter-class__input:focus {
  outline: 1px solid #6518c24b;
}

.meter-class__input::placeholder {
  font-size: 13px;
}

.meter-class__entry {
  border-bottom: 1px solid #eee;
}

.meter-class__entry:last-child {
  border-bottom: none !important;
}

.meter-class__title {
  font-size: 14px !important;
}

.meter-class__caption {
  color: #707070;
  font-size: 14px;
}

.tou-btn {
  position: absolute;
  top: 5px;
  font-size: 13px;
  right: 5px;
}
