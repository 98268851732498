.tou-card {
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  position: relative;
}

.tou-close__btn {
  position: absolute;
  top: 1px;
  right: 5px;
  cursor: pointer;
}

.tou-date,
.tou-bp {
  font-size: 12px;
}

.tou-value {
  /* font-weight: 510; */
  font-size: 13px;
}
